<template>
  <div class="router-container">
    <h1 class="title">{{ Title }}</h1>
    <div class="m-3">
      <h3 class="text warning">{{ Warning }}</h3>
      <p class="text warning">{{ errorHeaderText }}</p>
      <p class="m-4">{{ errorDetail }}</p>
    </div>
    <button class="back-button mb-4" v-on:click="back">
      <img src="../assets/icon-back-arrow.png" class="back-button-icon" />
      <text class="button-text">{{ BackToAccountManagement }}</text>
    </button>
    <p v-if="showPrimaryPuid()" class="puid">{{ PrimaryPuid }}</p>
    <p v-if="showSecondaryPuid()" class="puid">{{ SecondaryPuid }}</p>
  </div>
</template>

<script lang="js">
export default {
  data() {
    return {
      errorHeaderText: String,
      errorDetail: String,
    };
  },
  mounted() {
    this.errorHeaderText = this.$store.state.merging.errors.mergeError;
    this.errorDetail = this.$store.state.merging.errors.mergeErrorDetail;
    //this.errorHeaderText = "An error has occurred (1000)"
    //this.errorDetail = "An unknown error has occurred!"
  },
  computed: {
    PrimaryPuid() {
      return (
        "Primary PUID: " +
        this.$store.state.merging.primaryKeyring?.queryResponse?.puid
      );
    },
    SecondaryPuid() {
      return (
        "Secondary PUID: " +
        this.$store.state.merging.secondaryKeyring?.queryResponse?.puid
      );
    },
    Title() {
      return this.$store.getters.translate("MergeV2Title");
    },
    Warning() {
      return this.$store.getters.translate("MergeV2Warning");
    },
    BackToAccountManagement() {
      return this.$store.getters.translate("MergeV2BackToAccountManagementButton");
    },
  },
  methods: {
    back(e) {
      e.preventDefault();
      this.$store.commit("clearMergeErrors");
      this.$store.commit("clearMergeInfo");
      this.$router.push({ name: "home" });
    },
    showPrimaryPuid() {
      return !!this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
    },
    showSecondaryPuid() {
      return !!this.$store.state.merging.secondaryKeyring?.queryResponse?.puid;
    },
  },
};
</script>

<style scoped>
.error-header {
  color: red;
  text-align: center;
  font-weight: bold;
}

.return-button-text {
  margin-left: 25px;
  margin-bottom: 0;
}

.puid {
  font-size: 1.5em;
  margin-bottom: 0;
}
</style>
