export default {
  isAuthed: (state) => {
    return state.user.id_token !== null && state.user.id_token !== undefined;
  },
  translate: (state) => (key) => {
    return state.translations.translate(state.user.language, key);
  },
  translateDetail: (state) => (detail) => {
    return state.translations.translateDetail(state.user.language, detail);
  },
  translateWithPlatform: (state) => (key) => {
    return state.translations.translateWithPlatform(
      state.user.language,
      key,
      state.user.platform
    );
  },
  languageMap: (state) => {
    return state.translations.languageMap;
  },
  languageByCode: (state) => (code) => {
    for (const [key, value] of state.translations.languageMap.entries()) {
      if (value === code) {
        return key;
      }
    }
    return "";
  },

  primaryAccessToken: (state) => {
    return state.merging.primaryKeyring.access_tokens[
      state.merging.primaryPlatform
    ];
  },

  primaryKeyringAllAuthed: (state) => {
    return state.merging.primaryKeyring.queryResponse?.platforms.every(
      (platform) => {
        return (
          state.merging.primaryKeyring.access_tokens[platform.i] !== null &&
          state.merging.primaryKeyring.access_tokens[platform.i] !== undefined
        );
      }
    );
  },
  primaryKeyringAuthed: (state) => (platform) => {
    return state.merging.primaryKeyring.access_tokens[platform];
  },

  secondaryKeyringAllAuthed: (state) => {
    return state.merging.secondaryKeyring.queryResponse?.platforms.every(
      (platform) => {
        return (
          state.merging.secondaryKeyring.access_tokens[
            platform.identityProviderId
          ] !== null &&
          state.merging.secondaryKeyring.access_tokens[
            platform.identityProviderId
          ] !== undefined
        );
      }
    );
  },
  secondaryKeyringAuthed: (state) => (platform) => {
    return state.merging.secondaryKeyring.access_tokens[platform];
  },
  secondaryPlatformPretty: (state) => {
    const platform = state.merging.secondaryPlatform;
    switch (platform) {
      case "egs":
      case "epicgames":
      case "epic":
        return state.translations.translate(
          state.user.language,
          "EpicPlatform"
        );
      case "steam":
        return state.translations.translate(
          state.user.language,
          "SteamPlatform"
        );
      case "google":
      case "android":
        return state.translations.translate(
          state.user.language,
          "GooglePlatform"
        );
      case "apple":
        return state.translations.translate(
          state.user.language,
          "ApplePlatform"
        );
      case "microsoft":
      case "xbl":
        return state.translations.translate(
          state.user.language,
          "XboxPlatform"
        );
      case "itchio":
        return state.translations.translate(
          state.user.language,
          "ItchioPlatform"
        );
    }
  },
  platformData: (state) => {
    const platformData = {
      steam: {
        name: state.translations.translate(
          state.user.language,
          "SteamPlatform"
        ),
        logoSrc: require("../assets/Placeholder_steam.png"),
        url: `https://steamcommunity.com/oauth/login?response_type=token&client_id=${process.env.VUE_APP_STEAM_CLIENT_ID}`,
        platformKey: "steam",
      },
      epicgames: {
        name: state.translations.translate(state.user.language, "EpicPlatform"),
        logoSrc: require("../assets/Placeholder_epic.png"),
        url: `https://www.epicgames.com/id/authorize?client_id=${process.env.VUE_APP_EPIC_CLIENT_ID}&response_type=code&scope=basic_profile&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/epic`,
        platformKey: "epicgames",
      },
      google: {
        name: state.translations.translate(
          state.user.language,
          "GooglePlatform"
        ),
        logoSrc: require("../assets/Placeholder_google.png"),
        url: `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/google&response_type=code&scope=openid profile&prompt=select_account`,
        platformKey: "google",
      },
      apple: {
        name: state.translations.translate(
          state.user.language,
          "ApplePlatform"
        ),
        logoSrc: require("../assets/Placeholder_apple.png"),
        url: `https://appleid.apple.com/auth/authorize?client_id=${process.env.VUE_APP_APPLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/apple&response_type=code id_token&scope=name email&response_mode=form_post`,
        platformKey: "apple",
      },
      xbl: {
        name: state.translations.translate(state.user.language, "XboxPlatform"),
        logoSrc: require("../assets/Placeholder_microsoft.png"),
        cookie: `microsoft_sandbox=${process.env.VUE_APP_MICROSOFT_SANDBOX_ID};path=/;samesite=none;secure`,
        url: `https://login.live.com/oauth20_authorize.srf?client_id=${process.env.VUE_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/microsoft&scope=XboxLive.signin`,
        platformKey: "xbl",
      },
      itchio: {
        name: state.translations.translate(
          state.user.language,
          "ItchioPlatform"
        ),
        logoSrc: require("../assets/Placeholder_itchio.png"),
        url: `https://itch.io/user/oauth?client_id=${process.env.VUE_APP_ITCHIO_CLIENT_ID}&scope=profile:me&response_type=token&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/itchio`,
        platformKey: "itchio",
      },
    };
    // We need the windows 10 sandbox url for staging
    if (process.env.VUE_APP_MICROSOFT_SANDBOX_ID !== "RETAIL") {
      platformData["xbl"] = {
        name: state.translations.translate(
          state.user.language,
          "WindowsPlatform"
        ),
        logoSrc: require("../assets/Placeholder_microsoft.png"),
        cookie: `microsoft_sandbox=${process.env.VUE_APP_WIN10_SANDBOX_ID};path=/;samesite=none;secure`,
        url: `https://login.live.com/oauth20_authorize.srf?client_id=${process.env.VUE_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/microsoft&scope=XboxLive.signin`,
        platformKey: "xbl",
      };
    }
    return platformData;
  },
};
