<template>
  <div class="router-container unlink-attention">
    <div class="header-info">
      <h1>{{ Title }}</h1>
      <h3 class="text warning" v-bind:class="!MergeDisabled && 'disabled'">
        {{ Warning }}
      </h3>
      <p
        class="text attention-header"
        v-bind:class="!MergeDisabled && 'disabled'"
      >
        {{ Header }}
      </p>
    </div>

    <div>
      <h1 class="primary-header">{{ AccountToBeLinked }}</h1>
      <div class="platform-box">
        <p class="puid">{{ AccountId }}</p>
        <div v-for="(platform, key) in platforms" :key="key">
          <div class="row" v-if="ShowLinkedPlatform(key)">
            <button class="account-button">
              <img class="logo" v-bind:src="platform.logoSrc" alt="Logo" />

              <h1 class="button-text">
                {{ platform.name }}
              </h1>
            </button>
            <button
              class="unlink-button button-text unlink-tooltip"
              v-if="!ShowSignInButton(key)"
              v-on:click="Unlink(key)"
              :disabled="UnlinkDisabled"
            >
              {{ UnlinkButton }}
              <div class="tooltip-text">
                <p class="tooltip-header warning-text">
                  {{ MergingAttention }}
                </p>
                <p class="warning-text">
                  {{ MergingAttentionTooltipWarning }}
                </p>
                <p>{{ MergingAttentionTooltipDetails }}</p>
              </div>
            </button>
            <button
              class="sign-in-button button-text"
              v-if="ShowSignInButton(key)"
              v-on:click="SignIn(platform)"
            >
              {{ SignInText }}
            </button>
          </div>
        </div>
        <img
          v-if="MergeDisabled"
          class="mergeable-icon"
          src="../assets/MergeCross.png"
        />
        <img
          v-if="!MergeDisabled"
          class="mergeable-icon"
          src="../assets/MergeCheckmark.png"
        />
      </div>
    </div>

    <div class="help-container" v-bind:class="!MergeDisabled && 'disabled'">
      <p class="text help">
        <b>{{ UnlinkHelpHeader }}</b>
      </p>
      <p class="text help mb-2">
        <i>{{ UnlinkHelpDetail1 }}</i>
      </p>
      <p class="text help mb-2">
        <i>{{ UnlinkHelpDetail2 }}</i>
      </p>
      <p class="text mb-2 text-decoration-underline">
        <b>
          <i>
            {{ UnlinkHelpDetailFAQ }}
          </i>
        </b>
      </p>
    </div>

    <button
      class="submit-button"
      v-on:click="Continue"
      :disabled="MergeDisabled"
    >
      {{ ContinueButton }}
    </button>
    <button class="back-button" v-if="MergeDisabled" v-on:click="back">
      <img src="../assets/icon-back-arrow.png" class="back-button-icon" />
      <text class="button-text">{{ ReturnToAccountManagement }}</text>
    </button>
  </div>
</template>

<script lang="js">
export default {
  data() {
    return {
      platforms: this.$store.getters.platformData,
    };
  },
  async mounted() {
    // now that we've redirected successfully, we can delete the cookie
    document.cookie =
      "REDIRECT=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;samesite=none;secure";

    if (!this.$route.query.token || !this.$route.query.store) {
      return;
    }
    const eosAuthBody = {
      store: this.$route.query.store,
      token: this.$route.query.token,
      name: this.$route.query.name,
      hash: this.$route.query.userhash,
    };
    let store = eosAuthBody.store;
    // TODO: HANDLE THIS SOMEWHERE ELSE? ALSO NEEDED IN MERGE SIGNIN
    if (store === "epic") {
      // The stores used by the backend and the stores used by the /me keychain route
      // are all the same - except for the Epic store and XBL
      store = "epicgames";
    } else if (store === "microsoft") {
      store = "xbl";
    }
    let meta = {
      mergeId: this.$route.query.mergeId,
      type: "merge_attention_unlink",
    }
    const authRes = await this.$store.dispatch("eosAuth", {data: eosAuthBody, meta: meta});
    if (authRes.status && (authRes.status >= 300 || authRes.status < 200)) {
      this.$store.commit("saveMergingError", {
        error: "Error Unlinking Accounts",
        errorDetail: authRes.errors[0].error_text,
      });
      this.$router.push({ name: "mergeError" });
      return;
    }
    this.$store.commit("addSecondaryMergeAccessToken", {
      platform: store,
      token: authRes.token,
    });
  },
  computed: {
    AccountId() {
      const primaryAccountId = this.$store.getters.translate("MergeV2PrimaryAccountId");
      const primaryAccountIdString = this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
      return `${primaryAccountId}: ${primaryAccountIdString ?? ""}`;
    },
    Title() {
      return this.$store.getters.translate("MergeV2Title");
    },
    Header() {
      return this.$store.getters.translate("MergeV2UnlinkHeader");
    },
    Warning() {
      return this.$store.getters.translate("MergeV2Warning");
    },
    AttentionMessage() {
      return this.$store.getters.translate("MergingAttentionMessage");
    },
    AccountToBeLinked() {
      return this.$store.getters.translate("MergeV2AccountToLink");
    },
    SignInText() {
      return this.$store.getters.translate("MergingSignIn");
    },
    UnlinkButton() {
      return this.$store.getters.translate("MergingUnlink");
    },
    ContinueButton() {
      return this.$store.getters.translate("MergingContinue");
    },
    MergingAttention() {
      return this.$store.getters.translate("MergingAttention");
    },
    MergingAttentionTooltipWarning() {
      return this.$store.getters.translate("MergingAttentionTooltipWarning");
    },
    MergingAttentionTooltipDetails() {
      return this.$store.getters.translate("MergingAttentionTooltipDetails");
    },
    UnlinkHelpHeader() {
      return this.$store.getters.translate("MergeV2UnlinkHelpHeader");
    },
    UnlinkHelpDetail1() {
      return this.$store.getters.translate("MergeV2UnlinkHelpDetail1");
    },
    UnlinkHelpDetail2() {
      return this.$store.getters.translate("MergeV2UnlinkHelpDetail2");
    },
    UnlinkHelpDetailFAQ() {
      return this.$store.getters.translate("MergeV2UnlinkHelpDetailFAQ");
    },
    ReturnToAccountManagement() {
      return this.$store.getters.translate("MergeV2BackToAccountManagementButton");
    },
    MergeDisabled() {
      return this.SecondaryPlatforms?.length > 1;
    },
    UnlinkDisabled() {
      const signedIn = Object.values(this.SecondaryTokens).filter(x => x)
      return signedIn.length <= 1;
    },
    SecondaryPlatforms() {
      return this.$store.state.merging.secondaryKeyring.queryResponse?.platforms;
    },
    SecondaryTokens() {
      return this.$store.state.merging.secondaryKeyring?.access_tokens;
    }
  },
  methods: {
    async Continue(e) {
      e.preventDefault();
      this.$router.push({ name: "previewMerge" });
    },
    SignIn(platform) {
      if (process.env.VUE_APP_SKIP_OAUTH === "true") {
        this.SecondaryTokens[platform.platformKey] = `${platform.platformKey}-token`;
        return;
      }

      if (platform.cookie) {
        document.cookie = platform.cookie;
      }
      document.cookie = `REDIRECT=mergeAttentionUnlink;max-age=600;path=/;samesite=none;secure`; // expires after 10 min
      window.location.href = platform.url;
    },
    async Unlink(platform) {
      const token = this.$store.state.merging.secondaryKeyring.access_tokens[platform];
      const res = await this.$store.dispatch("unlinkAccount", {
        token: token,
      });

      if (res?.errors) {
        this.$store.commit("saveMergingError", {
          error: this.$store.getters.translate("BackendErrorGeneric"),
          errorDetail: this.$store.getters.translate("MergeErrorUnlink"),
        });
        this.$router.push({ name: "mergeError" });
        return;
      }

      this.$store.commit("unlinkSecondaryPlatform", platform);
      // Not used on this page, but will reflect on other pages
      this.PopulatePlatformLists();
      return;
    },
    async back(e) {
      e.preventDefault();

      this.$router.push({ name: "previewMerge" });
    },
    ShowLinkedPlatform(platform) {
      return this.SecondaryPlatforms?.includes(platform);
    },
    ShowSignInButton(platform) {
      return !this.SecondaryTokens[platform];
    },
  },
};
</script>
<style scoped>
.platform-box {
  background-color: black;
  border: 4px solid white;
  border-radius: 10px;
  width: auto;
  min-height: 14em;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.5em;
  margin-bottom: 2em;
  justify-content: center;
  align-items: center;
}

.mergeable-icon {
  height: 3em;
  width: 3em;
  position: absolute;
  bottom: -1.65em;
  left: 45%;
}

.primary-header {
  order: -3;
  font-family: brook, Arial, sans-serif;
  font-size: xx-large;
  margin: 10px;
}

:lang(tr).primary-header,
:lang(ru-RU).primary-header,
:lang(pl).primary-header {
  font-size: large;
  font-family: sans-serif;
  text-transform: uppercase;
}

.button-box {
  display: flex;
  left: 34%;
  margin-top: 3%;
}

.header-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 35em;
  margin-bottom: 0.5em;
}

.attention-header {
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}

.warning.disabled,
.attention-header.disabled {
  opacity: 0.1;
}

.warning.disabled {
  opacity: 0.1;
}

.help-container {
  max-width: 35em;
}

.help {
  font-size: 0.9em;
}

.help-container.disabled {
  opacity: 0.1;
}

/* small screens */
@media screen and (max-width: 500px) {
  .platform-box {
    width: 66vw;
    min-height: 14em;
  }
}

/* loc and overflow */
.unlink-attention :lang(th).submit-button,
.unlink-attention :lang(ru-RU).submit-button {
  font-size: x-large;
}
</style>
