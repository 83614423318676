<template>
  <nav class="navbar navbar-expand navbar-dark">
    <a class="navbar-brand m-1 mx-3" href="/">
      <img src="../assets/AmongUsLogo.png" height="40" alt="Among Us" />
    </a>

    <ul class="navbar-nav ms-auto">
      <li class="nav-item dropdown">
        <a
          class="nav-link dropdown-bs-toggle"
          id="navbarDropdownMenuLink"
          role="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {{ CurrentLanguage }}
        </a>
        <div
          class="dropdown-menu text-center dropdown-multicol"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <button
            class="dropdown-item"
            type="button"
            v-for="[lang] in languageMap"
            v-bind:key="lang"
            v-on:click="languageSelect(lang)"
          >
            {{ lang }}
          </button>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script lang="js">
export default {
  data() {
    return {
      CurrentLanguage: "English",
      languageMap: {},
    };
  },
  mounted() {
    this.languageMap = this.$store.getters.languageMap;
    this.CurrentLanguage = this.$store.getters.languageByCode(
      this.$store.state.user.language
    );
  },
  methods: {
    languageSelect(lang) {
      this.CurrentLanguage = lang;
      this.$store.state.user.language = this.languageMap.get(lang);
      sessionStorage.setItem("language", this.languageMap.get(lang));
      location.reload();
    },
  },
};
</script>

<style scoped>
.navbar {
  background-color: rgb(20 20 20 / 95%);
  width: 100vw;
}

.navbar-nav > li > .dropdown-menu {
  background-color: rgb(20 20 20 / 90%);
  top: 54px;
  left: unset;
  right: 0;
}

.navbar-nav > li > .dropdown-menu > .dropdown-item {
  color: white;
  vertical-align: middle;
}

div.dropdown-multicol {
  width: 100vw;
}

button.dropdown-item {
  display: inline-block;
  width: 23vw;
}

@media screen and (max-width: 450px) {
  .navbar-nav > li > .dropdown-menu {
    top: 54px;
    right: -17px;
    border-radius: 0;
  }

  button.dropdown-item {
    width: 35vw;
  }
}
</style>
