<template>
  <div class="box">
    <div class="content">
      <table style="position: relative; bottom: 57px">
        <tr>
          <td>
            <p class="text" align="center">{{ ErrorMessage }}</p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script lang="js">
export default {
  data() {
    return {
      ErrorMessage: this.$store.getters.translate("AuthErrorMessage"),
    };
  },
};
</script>
