// used to get/set the query response
export const PRIMARY_KEYRING_QUERY_KEY = "primary_keyring_query";
export const SECONDARY_KEYRING_QUERY_KEY = "secondary_keyring_query";

// used to specify merge access token keys in storage
export const PRIMARY_ACCESS_TOKEN_PREFIX = "primary_access_token_";
export const SECONDARY_ACCESS_TOKEN_PREFIX = "secondary_access_token_";

export const WILL_LOSE_PERMISSIONS_KEY = "will_lose_permissions";
export const WILL_LOSE_FRIENDS_KEY = "will_lose_friends";

// identityProviderId that is returned from the /me keyring call
export const mergePlatforms = {
  steam: "steam",
  epicgames: "epicgames",
  google: "google",
  apple: "apple",
  xbl: "xbl",
  itchio: "itchio",
};

export const KeyringPlatformToBackendPlatformMap = {
  epic: "epicgames",
  epicgames: "epicgames",
  microsoft: "xbl",
  xbl: "xbl",
  steam: "steam",
  google: "google",
  apple: "apple",
  itchio: "itchio",
};
