<template>
  <div>
    <h1>{{ AccountManagement }}</h1>

    <div class="sign-in-container">
      <div class="signed-in-container">
        <p class="text">{{ PrimaryAccountTitle }}</p>
        <div>
          <img class="crewmate-icon" src="../assets/RedCrewmate.webp" />
          <img
            v-for="platform in page.signedIn"
            :key="platform.platformKey"
            class="signed-in-logo"
            v-bind:src="platform.logoSrc"
            alt="Logo"
          />
          <img
            v-for="platform in page.linked"
            :key="platform.platformKey"
            class="signed-in-logo"
            v-bind:src="platform.logoSrc"
            alt="Logo"
          />
        </div>
        <div>
          <p class="primary-account">{{ PrimaryAccount }}</p>
          <p class="account-id">{{ AccountId }}</p>
        </div>
        <div v-for="platform in page.signedIn" :key="platform.platformKey">
          <table>
            <tr>
              <td>
                <div class="account-button">
                  <img class="logo" v-bind:src="platform.logoSrc" alt="Logo" />
                  <h1 class="button-text">{{ platform.name }}</h1>
                </div>
              </td>
              <td>
                <button
                  class="unlink-button button-text unlink-tooltip"
                  v-on:click="Unlink(platform.platformKey)"
                  :disabled="DisableUnlinkButton"
                >
                  {{ UnlinkText }}
                  <div class="tooltip-text">
                    <p class="tooltip-header warning-text">
                      {{ MergingAttention }}
                    </p>
                    <p class="warning-text">
                      {{ MergingSignInTooltipWarning }}
                    </p>
                    <p>{{ MergingSignInTooltipDetails }}</p>
                  </div>
                </button>
              </td>
            </tr>
          </table>
        </div>
        <div v-for="platform in page.linked" :key="platform.platformKey">
          <table>
            <tr>
              <td>
                <div class="account-button">
                  <img class="logo" :src="platform.logoSrc" alt="Logo" />
                  <h1 class="button-text">{{ platform.name }}</h1>
                </div>
              </td>
              <td>
                <button
                  class="sign-in-button button-text"
                  v-on:click="SignInOrLink(platform)"
                >
                  {{ SignInText }}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <hr class="half-width" />
      <p class="link-account-pane-header">{{ LinkPlatformsHeader }}</p>
      <p class="link-account-pane-detail">{{ LinkPlatformsDetail }}</p>
      <div v-for="platform in page.others" :key="platform.platformKey">
        <table>
          <tr>
            <td>
              <div class="account-button">
                <img class="logo" :src="platform.logoSrc" alt="Logo" />
                <h1 class="button-text">{{ platform.name }}</h1>
              </div>
            </td>
            <td>
              <button
                class="link-button button-text"
                v-on:click="SignInOrLink(platform)"
              >
                {{ LinkText }}
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { KeyringPlatformToBackendPlatformMap } from "../store/_shared"

export default {
  data() {
    return {
      platforms: this.$store.getters.platformData,

      page: {
        signedIn: [],
        linked: [],
        others: [],
      },
    };
  },
  computed: {
    DisableUnlinkButton() {
      return this.page.signedIn.length === 1;
    },
    AccountManagement() {
      return this.$store.getters.translate("MergingAccountManagement");
    },
    PrimaryAccountTitle() {
      return this.$store.getters.translate("MergeV2PrimaryAccountPane");
    },
    PrimaryAccount() {
      return this.$store.getters.translate("MergeV2PrimaryAccount")
    },
    AccountId() {
      const primaryAccountId = this.$store.getters.translate("MergeV2PrimaryAccountId");
      const primaryAccountIdString = this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
      return `${primaryAccountId}: ${primaryAccountIdString ?? ""}`;
    },
    LinkText() {
      return this.$store.getters.translate("MergingLink");
    },
    UnlinkText() {
      return this.$store.getters.translate("MergingUnlink");
    },
    SignInText() {
      return this.$store.getters.translate("MergingSignIn");
    },
    LinkPlatformsHeader() {
      return this.$store.getters.translate("MergeV2LinkAccountPane");
    },
    LinkPlatformsDetail() {
      return this.$store.getters.translate("MergeV2LinkAccountDetail");
    },
    MergingAttention() {
      return this.$store.getters.translate("MergingAttention");
    },
    MergingSignInTooltipWarning() {
      return this.$store.getters.translate("MergingSignInTooltipWarning");
    },
    MergingSignInTooltipDetails() {
      return this.$store.getters.translate("MergingSignInTooltipDetails");
    },
  },
  methods: {
    SignInOrLink(platform) {
      if (process.env.VUE_APP_SKIP_OAUTH === "true") {
        let idx = this.page.linked.indexOf(platform)
        if (idx !== -1) {
          this.page.linked.splice(idx, 1);
          this.page.signedIn.push(platform);
        } else {
          const eosAuthBody = {
            store: platform.platformKey,
            token: "platformToken",
          };

          const eosAuthRes = {
            token: "accessToken",
            idToken: "idToken",
          };
          this.SignedIntoAnotherKeyring(eosAuthBody, eosAuthRes, platform.platformKey)
       }
        return;
      }
      if (platform.cookie) {
        document.cookie = platform.cookie;
      }
      document.cookie = `REDIRECT=merge;max-age=600;path=/;samesite=none;secure`; // expires after 10 min
      window.location.href = platform.url;
    },
    async Unlink(platform) {
      const token = this.$store.state.merging.primaryKeyring.access_tokens[platform];
      const res = await this.$store.dispatch("unlinkAccount", { token: token });

      if (!res) {
        this.page.error = {
          detail: this.$store.getters.translate("LinkingErrorGenericError")
        };
        return;
      }

      if (res.errors) {
        this.$store.commit("saveMergingError", {
          error: this.$store.getters.translate("MergeErrorUnlink"),
          errorDetail: res.errors[0].error_text,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }

      if (res.status >= 400) {
        this.page.error = {
          detail: this.$store.getters.translate("LinkingErrorGenericError")
        };
        return;
      }

      this.$store.commit("unlinkPrimaryPlatform", platform);
      this.PopulatePlatformLists();
      return;
    },

    // Helpers for the mounted hook
    async FirstVisit(eosAuthBody, store) {
      const meta = {
        mergeId: this.$route.query.mergeId,
        type: "first_visit",
      }
      const authRes = await this.$store.dispatch("eosAuth", {data: eosAuthBody, meta: meta});
      if (authRes.errors) {
        this.$store.commit("saveMergingError", {
          error: authRes.errors[0].title,
          errorDetail: authRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }
      this.$store.commit("addPrimaryMergeAccessToken", {
        platform: store,
        token: authRes.token,
      });
      this.$store.commit("savePrimaryMergeDetails", {
        platform: store,
        id_token: authRes.id_token,
        platform_token: eosAuthBody.token,
        name: eosAuthBody.name,
        hash: eosAuthBody.hash,
      });
      const queryRes = await this.$store.dispatch(
        "queryPrimaryAccountBeforeMerge",
        authRes
      );

      if (queryRes.errors) {
        this.$store.commit("saveMergingError", {
          error: queryRes.errors[0].title,
          errorDetail: queryRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
      }
    },
    async LinkDanglingAccount(eosAuthBody, primaryPlatform, secondaryPlatform) {
      const primaryAccessToken =
        this.$store.state.merging.primaryKeyring.access_tokens[primaryPlatform];
      const primaryIdToken = this.$store.state.merging.primaryIdToken;
      const res = await this.$store.dispatch("linkBareAccount", {
        data: {
          type: "link_bare_account",
          attributes: {
            link_to_access_token: primaryAccessToken,
            link_to_id_token: primaryIdToken,
            link_from_platform_token: eosAuthBody.token,
            link_from_platform: secondaryPlatform,
            link_from_name: eosAuthBody.name,
            link_from_hash: eosAuthBody.hash,
          },
        },
      });
      if (res?.errors) {
        this.$store.commit("saveMergingError", {
          error: res.errors[0].title,
          errorDetail: res.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }

      const queryRes = await this.$store.dispatch(
        "queryPrimaryAccountBeforeMerge",
        {
          token: primaryAccessToken,
          id_token: primaryIdToken,
        }
      );
      if (queryRes.errors) {
        this.$store.commit("saveMergingError", {
          error: queryRes.errors[0].title,
          errorDetail: queryRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }
    },
    async SignedIntoAnotherKeyring(eosAuthBody, authRes, store) {
      this.$store.commit("addSecondaryMergeAccessToken", {
        platform: store,
        token: authRes.token,
      });
      this.$store.commit("saveSecondaryMergeDetails", {
        platform: store,
        id_token: authRes.id_token,
        platform_token: eosAuthBody.token,
        name: eosAuthBody.name,
        hash: eosAuthBody.hash,
      });

      const queryRes = await this.$store.dispatch(
        "querySecondaryAccountBeforeMerge",
        authRes
      );

      if (queryRes.errors) {
        this.$store.commit("saveMergingError", {
          error: queryRes.errors[0].title,
          errorDetail: queryRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }

      if (queryRes.data.swap_for_kws) {
        this.$store.commit("swapPrimaryAndSecondary");
      }

      this.$router.push({ name: "previewMerge" });
    },
    PopulatePlatformLists() {
      const primaryPlatforms = this.$store.state.merging.primaryKeyring.queryResponse?.platforms;
      const primaryTokens = this.$store.state.merging.primaryKeyring.access_tokens;
      if (!primaryPlatforms || !primaryTokens) {
        return;
      }
      this.page.signedIn = [];
      this.page.linked = [];
      this.page.others = [];

      // populate signed in and linked lists
      primaryPlatforms.forEach((platform) => {
        if (primaryTokens[platform]) {
          this.page.signedIn.push(this.platforms[platform]);
        } else if (this.platforms[platform]) {
          this.page.linked.push(this.platforms[platform]);
        }
      });

      // populate other platforms list
      for (const platform in this.platforms) {
        if (!primaryPlatforms.includes(platform)) {
          this.page.others.push(this.platforms[platform]);
        }
      }
    },
    async authNewDanglingAccount(eosAuthBody) {
      const meta = {
        mergeId: this.$route.query.mergeId,
        type: "auth_new_dangling_account",
      }
      const authRes = await this.$store.dispatch("eosAuth", {data: eosAuthBody, meta: meta});
      if (authRes?.errors) {
        this.$store.commit("saveMergingError", {
          error: authRes.errors[0].title,
          errorDetail: authRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }
      this.$store.commit("addPrimaryMergeAccessToken", {
        platform: eosAuthBody.store,
        token: authRes.token,
      });
      this.PopulatePlatformLists();
    },
  },

  async mounted() {
    // now that we've redirected successfully, we can deconste the cookie
    document.cookie =
      "REDIRECT=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;samesite=none;secure";
    const eosAuthBody = {
      store: this.$route.query.store,
      token: this.$route.query.token,
      name: this.$route.query.name,
      hash: this.$route.query.userhash,
    };

    const primaryKeyringQueryResponse =
      this.$store.state.merging.primaryKeyring.queryResponse;

    let store = eosAuthBody.store;
    if (!store) {
      if (primaryKeyringQueryResponse) {
        this.PopulatePlatformLists();
      } else {
        this.$router.push({ name: "home" });
      }
      return;
    }

    store = KeyringPlatformToBackendPlatformMap[store]

    if (!primaryKeyringQueryResponse) {
      await this.FirstVisit(eosAuthBody, store);
      this.PopulatePlatformLists();
      return;
    }

    if (this.$store.getters.primaryKeyringAuthed(store)) {
      this.PopulatePlatformLists();
      return;
    }
    // get EOS tokens
    const meta = {
        mergeId: this.$route.query.mergeId,
        type: "secondary_auth",
    }
    const authRes = await this.$store.dispatch("eosAuth", {data: eosAuthBody, meta: meta});
    const primaryPlatform = this.$store.state.merging.primaryPlatform;
    if (authRes.errors) {
      if (authRes.errors[0]?.code === 404 && primaryPlatform) {
        await this.LinkDanglingAccount(eosAuthBody, primaryPlatform, store)
        await this.authNewDanglingAccount(eosAuthBody);
        this.PopulatePlatformLists();
      } else {
        this.$store.commit("saveMergingError", {
          error: authRes.errors[0].title,
          errorDetail: authRes.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
      }
      return;
    }

    if (
      primaryKeyringQueryResponse &&
      !primaryKeyringQueryResponse.platforms.includes(store)
    ) {
      await this.SignedIntoAnotherKeyring(eosAuthBody, authRes, store);
      return;
    }

    // save the new token to the primary keyring
    this.$store.commit("addPrimaryMergeAccessToken", {
      platform: store,
      token: authRes.token,
    });

    this.PopulatePlatformLists();
  },
};
</script>

<style scoped>
hr.half-width {
  margin: 20px;
  border: 1px solid white;
  opacity: 1;
  width: 30em;
}

.sign-in-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 500px;
}

.signed-in-container {
  border: 4px solid #0f0;
  background: black;
  border-radius: 8px;
  padding: 15px;
  width: fit-content;
}

.crewmate-icon {
  width: 40px;
  margin: 10px;
  margin-right: 50px;
}

.signed-in-logo {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.unlink-tooltip:hover .tooltip-text {
  visibility: visible;
}

.link-account-pane-header {
  font-family: brook, sans-serif;
  font-size: 2em;
  margin: 0;
}

:lang(pl).link-account-pane-header,
:lang(tr).link-account-pane-header,
:lang(ru-RU).link-account-pane-header {
  font-family: sans-serif;
  text-transform: uppercase;
}

.link-account-pane-detail {
  font-family: Arial, sans-serif;
  font-size: 0.9em;
  font-style: italic;
}
</style>
