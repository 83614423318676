import TranslationManager from "../translations/translations";
import {
  PRIMARY_KEYRING_QUERY_KEY,
  SECONDARY_KEYRING_QUERY_KEY,
  PRIMARY_ACCESS_TOKEN_PREFIX,
  SECONDARY_ACCESS_TOKEN_PREFIX,
  WILL_LOSE_PERMISSIONS_KEY,
  WILL_LOSE_FRIENDS_KEY,
  mergePlatforms,
} from "./_shared";

export default {
  translations: new TranslationManager(),

  user: {
    language: sessionStorage.getItem("language"),
    platform: sessionStorage.getItem("platform"),
    id_token: sessionStorage.getItem("id_token"),
    token: sessionStorage.getItem("token"),
    twitch_token: sessionStorage.getItem("twitch_token"),
  },
  merging: {
    willLosePermissions: JSON.parse(
      sessionStorage.getItem(WILL_LOSE_PERMISSIONS_KEY)
    ),
    willLoseFriends: JSON.parse(sessionStorage.getItem(WILL_LOSE_FRIENDS_KEY)),

    primaryKeyring: {
      queryResponse: JSON.parse(
        sessionStorage.getItem(PRIMARY_KEYRING_QUERY_KEY)
      ),
      access_tokens: {
        [mergePlatforms.steam]: sessionStorage.getItem(
          PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.steam
        ),
        [mergePlatforms.epicgames]: sessionStorage.getItem(
          PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.epicgames
        ),
        [mergePlatforms.google]: sessionStorage.getItem(
          PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.google
        ),
        [mergePlatforms.apple]: sessionStorage.getItem(
          PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.apple
        ),
        [mergePlatforms.xbl]: sessionStorage.getItem(
          PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.xbl
        ),
        [mergePlatforms.itchio]: sessionStorage.getItem(
          PRIMARY_ACCESS_TOKEN_PREFIX + mergePlatforms.itchio
        ),
      },
    },

    secondaryKeyring: {
      queryResponse: JSON.parse(
        sessionStorage.getItem(SECONDARY_KEYRING_QUERY_KEY)
      ),
      access_tokens: {
        [mergePlatforms.steam]: sessionStorage.getItem(
          SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.steam
        ),
        [mergePlatforms.epicgames]: sessionStorage.getItem(
          SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.epicgames
        ),
        [mergePlatforms.google]: sessionStorage.getItem(
          SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.google
        ),
        [mergePlatforms.apple]: sessionStorage.getItem(
          SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.apple
        ),
        [mergePlatforms.xbl]: sessionStorage.getItem(
          SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.xbl
        ),
        [mergePlatforms.itchio]: sessionStorage.getItem(
          SECONDARY_ACCESS_TOKEN_PREFIX + mergePlatforms.itchio
        ),
      },
    },
    primaryPlatform: sessionStorage.getItem("primary_platform"),
    primaryIdToken: sessionStorage.getItem("primary_id_token"),
    primaryPlatformToken: sessionStorage.getItem("primary_platform_token"),
    primaryUserName: sessionStorage.getItem("primary_user_name"),
    primaryHash: sessionStorage.getItem("primary_hash"),
    secondaryPlatform: sessionStorage.getItem("secondary_platform"),
    secondaryIdToken: sessionStorage.getItem("secondary_id_token"),
    secondaryPlatformToken: sessionStorage.getItem("secondary_platform_token"),
    secondaryUserName: sessionStorage.getItem("secondary_user_name"),
    secondaryHash: sessionStorage.getItem("secondary_hash"),
    errors: {
      mergeError: sessionStorage.getItem("mergeError"),
      mergeErrorDetail: sessionStorage.getItem("mergeErrorDetail"),
    },
  },
};
