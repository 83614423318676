<template>
  <div v-bind:lang="lang">
    <nav-bar></nav-bar>
    <router-view class="router-container"></router-view>
    <accounts-footer></accounts-footer>
  </div>
</template>

<script>
import AccountsFooter from "./components/AccountsFooter.vue";
import NavBar from "./components/NavBar.vue";

export default {
  beforeMount() {
    // TODO: If we end up adding lang tags to more complex routes.. (/twitch-drops/en-US), we will need to improve this
    const langCode = window.location.pathname.replace("/", "");
    const url = window.location.origin;
    const language = this.$store.getters.languageByCode(langCode);
    if (language) {
      this.$store.state.user.language = langCode;
      sessionStorage.setItem("language", langCode);
      location.href = url;
    } else if (!this.$store.state.user.language) {
      this.$store.state.user.language = "en-US";
      sessionStorage.setItem("language", "en-US");
    }
  },
  computed: {
    lang() {
      return this.$store.state.user.language;
    },
  },
  components: { NavBar, AccountsFooter },
};
</script>

<style>
body {
  height: 100vh;
  width: 100%;
  background-color: black;
  color: white;
  background-image: url("./assets/Background.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.router-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
  padding-top: 1em;
  padding-bottom: 2em;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

.text {
  font-family: Arial, sans-serif;
  color: white;
  position: relative;
  margin: 0;
}

.button-text {
  font-family: brook, Arial, sans-serif;
  color: white;
  position: relative;
  font-size: xx-large;
  font-weight: normal;
  text-align: left;
}

:lang(tr).button-text {
  font-family: sans-serif;
  font-size: medium;
  text-transform: uppercase;
}

:lang(ru-RU).button-text {
  font-family: sans-serif;
  font-size: small;
  text-transform: uppercase;
}

:lang(de-DE).button-text {
  font-size: x-large;
}

.logo {
  /* for logos on the buttons of the homepage */
  height: 35px;
  position: relative;
  margin: 10px;
  margin-right: 15px;
  transform: initial;
}

.store-logo {
  /* for the logo on the "signed into {store}" for success and pin entry pages */
  height: 45px;
  position: relative;
}

.content {
  display: table-cell;
  vertical-align: middle;
  width: 100vw;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.box .header {
  flex: 0 1 auto;
}

.box .content {
  flex: 1 1 auto;
}

div.dropdown-multicol {
  width: 100vw;
}

button.dropdown-item {
  display: inline-block;
  width: 12%;
}

table {
  margin-left: auto;
  margin-right: auto;
  border: 3px;
  border-color: black;
  height: 100%;
}

.account-button {
  background-color: black;
  border: 3px solid white;
  border-radius: 8px;
  width: 300px;
  height: 60px;
  position: relative;
  display: flex;
  margin-bottom: 10px;
  text-align: center;
  align-items: center;
}

.account-button:enabled:hover {
  border-color: #5bbc2b;
  cursor: pointer;
}

.account-tile {
  background-color: black;
  border: 3px solid white;
  border-radius: 8px;
  width: 250px;
  height: 50px;
  position: relative;
  display: flex;
  margin-bottom: 10px;
  text-align: center;
  align-items: center;
}

.account-tile .button-text {
  font-size: 1.5em;
}

.account-button :lang(fr-FR).button-text {
  font-size: x-large;
}

.account-button :lang(pl).button-text {
  font-size: large;
}

:lang(tr).account-tile .button-text,
:lang(ru-RU).account-tile .button-text {
  font-family: sans-serif;
  font-size: large;
  text-transform: uppercase;
}

.account-tile .logo {
  height: 25px;
}

input {
  background-color: black;
  border: 3px solid white;
  border-radius: 8px;
  color: white;
  font-family: Arial, sans-serif;
  font-size: large;
  text-indent: 10px;
  position: relative;
  width: 350px;
  height: 55px;
}

input:hover {
  border-color: #5bbc2b;
  cursor: pointer;
}

input:focus {
  border-color: #5bbc2b;
  cursor: pointer;
  outline: none !important;
}

input:invalid {
  border-color: #d63924;
  box-shadow: none;
}

input:placeholder-shown {
  font-style: italic;
}

.submit-button {
  background-color: black;
  border: 3px solid white;
  border-radius: 8px;
  color: white;
  font-family: brook, Arial, sans-serif;
  font-size: xx-large;
  display: inline-flex;
  justify-content: center; /* center text horizontally */
  align-items: center; /* center text vertically */
  position: relative;
  width: 300px;
  height: 65px;
  margin-bottom: 1.5vh;
}

:lang(pl).submit-button,
:lang(tr).submit-button,
:lang(ru-RU).submit-button {
  font-family: sans-serif;
  font-size: xx-large;
  text-transform: uppercase;
}

.submit-button:disabled {
  background-color: black;
  border-color: #888;
  cursor: not-allowed;
  opacity: 0.5;
}

.submit-button:enabled:hover {
  border-color: #5bbc2b;
  cursor: pointer;
}

.back-button {
  background-color: black;
  border: 3px solid white;
  border-radius: 8px;
  color: white;
  position: relative;
  width: 300px;
  height: 50px;
  align-items: center;
  justify-content: center;
}

/* stylelint-disable-next-line no-descending-specificity */
.back-button .button-text {
  font-size: x-large;
}

:lang(pl).back-button .button-text,
:lang(tr).back-button .button-text,
:lang(ru-RU).back-button .button-text {
  font-family: sans-serif;
  font-size: small;
  text-transform: uppercase;
}

.back-button:hover {
  border-color: #5bbc2b;
  cursor: pointer;
}

.back-button-icon {
  height: 1em;
  padding-right: 0.5em;
}

h1 {
  font-family: brook, sans-serif;
  font-size: 3.5em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  font-weight: normal;
  line-height: normal;
}

:lang(pl) h1,
:lang(tr) h1,
:lang(ru-RU) h1 {
  font-family: sans-serif;
  font-size: xx-large;
}

:lang(ja-JP) h1 {
  font-size: xx-large;
}

h2 {
  font-size: 1.25em;
  font-weight: bold;
  line-height: normal;
}

h3 {
  font-size: 1.5em;
  font-weight: bold;
  line-height: normal;
}

h4 {
  font-size: 1.5em;
  font-weight: bold;
  line-height: normal;
}

h5 {
  font-size: 1.3em;
  line-height: normal;
}

.warning {
  color: red;
  font-weight: bold;
  text-align: center;
  margin: 0;
}

.sign-in-button {
  background-color: black;
  border: 3px solid white;
  border-radius: 8px;
  width: 125px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 10px;
}

:lang(ko-KR).sign-in-button,
:lang(zh-ZW).sign-in-button,
:lang(zh-CN).sign-in-button,
:lang(zh-TW).sign-in-button,
:lang(ar).sign-in-button {
  font-size: large;
}

:lang(ja-JP).sign-in-button,
:lang(ar).sign-in-button {
  font-size: medium;
}

:lang(pl).sign-in-button,
:lang(ru-RU).sign-in-button,
:lang(th).sign-in-button {
  font-size: small;
  font-family: sans-serif;
  text-transform: uppercase;
}

:lang(tr).sign-in-button {
  font-size: x-small;
}

.sign-in-button:hover:enabled {
  border-color: #5bbc2b;
  cursor: pointer;
}

.link-button {
  background-color: black;
  border: 3px solid white;
  border-radius: 8px;
  width: 125px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 10px;
}

:lang(ko-KR).link-button,
:lang(zh-ZW).link-button,
:lang(zh-CN).link-button,
:lang(zh-TW).link-button,
:lang(ar).link-button {
  font-size: large;
}

:lang(ja-JP).link-button {
  font-size: medium;
}

:lang(pl).link-button,
:lang(ru-RU).link-button,
:lang(th).link-button,
:lang(tr).link-button {
  font-size: small;
}

:lang(pl).link-button .button-text {
  font-family: sans-serif;
  text-transform: uppercase;
}

.link-button:hover {
  border-color: #5bbc2b;
  cursor: pointer;
}

.unlink-tooltip .tooltip-text {
  visibility: hidden;
  width: 500px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 8px;
  border-radius: 8px;
  position: absolute;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  left: 110%;
  top: 0;
}

.unlink-tooltip .tooltip-text p {
  margin-bottom: 0;
  font-size: 0.4em;
}

.unlink-tooltip .tooltip-text .warning-text {
  color: #d63924;
  font-weight: bold;
}

.unlink-tooltip .tooltip-text .tooltip-header {
  font-size: 0.5em;
}

.unlink-tooltip:hover .tooltip-text {
  visibility: visible;
}

.unlink-button {
  background-color: #d63924;
  border: 3px solid #530d03;
  border-radius: 8px;
  width: 125px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-bottom: 10px;
}

:lang(ko-KR).unlink-button,
:lang(zh-ZW).unlink-button,
:lang(zh-CN).unlink-button,
:lang(zh-TW).unlink-button,
:lang(ar).unlink-button {
  font-size: large;
}

:lang(ja-JP).unlink-button {
  font-size: medium;
}

:lang(pl).unlink-button,
:lang(ru-RU).unlink-button,
:lang(th).unlink-button {
  font-size: small;
  font-family: sans-serif;
  text-transform: uppercase;
}

:lang(tr).unlink-button {
  font-size: x-small;
}

.unlink-button:disabled {
  background-color: #444;
  border: 3px solid #888;
  opacity: 0.5;
}

.unlink-button:disabled .tooltip-text {
  visibility: hidden;
}

.puid {
  font-family: brook, sans-serif;
}

:lang(tr).puid,
:lang(ru-RU).puid {
  font-family: sans-serif;
  text-transform: uppercase;
}

.account-id {
  float: right;
  font-family: brook, sans-serif;
  font-size: x-large;
  margin-right: 2%;
  align-self: flex-end;
  margin-bottom: 1%;
}

:lang(ko-KR).account-id,
:lang(zh-ZW).account-id,
:lang(zh-CN).account-id,
:lang(zh-TW).account-id,
:lang(th).account-id {
  font-size: x-large;
}

:lang(ar).account-id,
:lang(ja-JP).account-id {
  font-size: large;
}

:lang(pl).account-id,
:lang(tr).account-id,
:lang(ru-RU).account-id {
  font-size: small;
  font-family: sans-serif;
  text-transform: uppercase;
}

.primary-account {
  float: left;
  font-family: brook, sans-serif;
  color: #5bbc2b;
  font-size: x-large;
  margin-left: 2%;
  align-self: flex-start;
  margin-bottom: 1%;
}

:lang(ko-KR).primary-account,
:lang(zh-ZW).primary-account,
:lang(zh-CN).primary-account,
:lang(zh-TW).primary-account,
:lang(th).primary-account {
  font-size: x-large;
}

:lang(ar).primary-account,
:lang(ru-RU).primary-account,
:lang(ja-JP).primary-account {
  font-size: large;
}

:lang(pl).primary-account,
:lang(tr).primary-account,
:lang(ru-RU).primary-account {
  font-size: small;
  font-family: sans-serif;
  text-transform: uppercase;
}

/* If the screen size is 500px wide or less */
@media screen and (max-width: 500px) {
  h1 {
    font-size: 2.5em;
  }

  .navbar-nav > li > .dropdown-menu {
    top: 54px;
    right: -17px;
    border-radius: 0;
  }

  div.dropdown-multicol {
    width: 100vw;
  }

  button.dropdown-item {
    width: 48%;
  }

  .text {
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  img {
    height: 48px;
  }

  .account-button {
    width: 250px;
    height: 50px;
  }

  .account-button .button-text {
    font-size: x-large;
  }

  .account-button .logo {
    height: 25px;
  }

  .sign-in-container {
    max-width: 320px;
  }

  .primary-account {
    font-size: 1.25em;
  }

  .account-id {
    font-size: 1.25em;
  }

  .sign-in-button {
    font-size: 1.25em;
    width: 75px;
    height: 50px;
  }

  .unlink-button {
    font-size: 1.25em;
    width: 75px;
    height: 50px;
  }

  .link-button {
    font-size: 1.5em;
    width: 75px;
    height: 50px;
  }

  .link-account-pane-detail {
    margin: 20px;
  }

  .submit-button {
    width: 90vw;
  }

  .back-button {
    width: 90vw;
  }
}

@media screen and (max-width: 400px) {
  .account-button {
    width: 175px;
  }

  .account-button .button-text {
    font-size: 1.25em;
  }

  .account-id {
    font-size: 1em;
  }

  .primary-account {
    font-size: 1em;
  }
}
</style>
