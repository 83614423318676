import { createRouter, createWebHistory } from "vue-router";

import ThirdPartyAuthRedeem from "../components/ThirdPartyAuthRedeem.vue";
import FinishAuth from "../components/FinishAuth.vue";
import RedeemCode from "../components/RedeemCode.vue";
import ErrorView from "../components/ErrorView.vue";

import ThirdPartyAuthMerge from "../components/ThirdPartyAuthMerging.vue";
import KWSRequestPermissions from "../components/KWSRequestPermissions.vue";
import MergeFriendsAttention from "../components/MergeFriendsAttention.vue";
import MergeAccountManagement from "../components/MergeAccountManagement.vue";
import MergeAccountsPreview from "../components/MergeAccountsPreview.vue";
import MergeAttentionUnlink from "../components/MergeAttentionUnlink.vue";
import MergeSuccess from "../components/MergeSuccess.vue";
import MergeError from "../components/MergeError.vue";

// TODO: AU-3341 Twitch Drops
// import TwitchDrops from "../components/TwitchDrops.vue"
import PinEntry from "../components/PinEntry.vue";
import Success from "../components/SuccessPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: ThirdPartyAuthMerge,
  },
  {
    path: "/finish-auth",
    name: "finishAuth",
    component: FinishAuth,
  },
  {
    path: "/pin",
    name: "pinEntry",
    component: PinEntry,
  },
  {
    path: "/success",
    name: "success",
    component: Success,
  },
  {
    path: "/redeem/auth",
    name: "redeemAuth",
    component: ThirdPartyAuthRedeem,
  },
  {
    path: "/error",
    name: "error",
    component: ErrorView,
  },
  {
    path: "/merge-error",
    name: "mergeError",
    component: MergeError,
  },
  {
    path: "/redeem",
    name: "redeem",
    component: RedeemCode,
  },
  {
    path: "/account-management",
    name: "accountManagement",
    component: MergeAccountManagement,
  },
  {
    path: "/merge-attention",
    name: "mergeAttentionUnlink",
    component: MergeAttentionUnlink,
  },
  {
    path: "/preview-merge",
    name: "previewMerge",
    component: MergeAccountsPreview,
  },
  // TODO: AU-3341 Twitch Drops
  // {
  //   path: "/twitch-drops",
  //   name: "twitchAuth",
  //   component: TwitchDrops,
  // },

  {
    path: "/request-permissions",
    name: "requestPermissions",
    component: KWSRequestPermissions,
  },
  {
    path: "/merge-friends-attention",
    name: "mergeFriendsAttention",
    component: MergeFriendsAttention,
  },
  {
    path: "/merge-success",
    name: "mergeSuccess",
    component: MergeSuccess,
  },
];

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
