<template>
  <div class="router-container">
    <h1>{{ MergingAccountHeader }}</h1>
    <p class="restart-warning">
      {{ RestartWarning }}
    </p>

    <div id="buttonRow">
      <button class="account-button" id="steambtn" v-on:click="steamClick">
        <img
          class="logo"
          src="../assets/Placeholder_steam.png"
          alt="Steam Logo"
        />
        <h1 class="button-text" align="center">
          {{ SteamButton }}
        </h1>
      </button>

      <button class="account-button" id="epicbtn" v-on:click="epicClick">
        <img class="logo" src="../assets/Placeholder_epic.png" alt="EGS Logo" />
        <h1 class="button-text" align="center">
          {{ EpicButton }}
        </h1>
      </button>

      <button class="account-button" id="googlebtn" v-on:click="googleClick">
        <img
          class="logo"
          src="../assets/Placeholder_google.png"
          alt="Google Play Logo"
        />
        <h1 class="button-text" align="center">
          {{ GoogleButton }}
        </h1>
      </button>

      <button class="account-button" id="applebtn" v-on:click="appleClick">
        <img
          class="logo"
          src="../assets/Placeholder_apple.png"
          alt="Apple Logo"
        />
        <h1 class="button-text" align="center">
          {{ AppleButton }}
        </h1>
      </button>

      <button
        class="account-button"
        id="microsoftbtn"
        v-on:click="microsoftClick"
        v-if="!showWin10Button"
      >
        <img
          class="logo"
          src="../assets/Placeholder_microsoft.png"
          alt="Xbox Logo"
        />
        <h1 class="button-text" align="center">
          {{ MicrosoftButton }}
        </h1>
      </button>

      <!--This one is only used for staging because we need to differentiate Win10 from Xbox for sandbox reasons-->
      <button
        class="account-button"
        id="win10btn"
        v-on:click="win10Click"
        v-if="showWin10Button"
      >
        <img
          class="logo"
          src="../assets/Placeholder_microsoft.png"
          alt="Xbox Logo"
        />
        <h1 class="button-text" align="center">
          {{ Win10Button }}
        </h1>
      </button>

      <button class="account-button" id="itchiobtn" v-on:click="itchioClick">
        <img
          class="logo"
          src="../assets/Placeholder_itchio.png"
          alt="Itch.io Logo"
        />
        <h1 class="button-text" align="center">
          {{ ItchioButton }}
        </h1>
      </button>
    </div>
  </div>
</template>

<script lang="js">
export default {
  mounted() {
    const currLang = this.$store.state.user.language;
    this.$store.commit("clearMergeInfo");
    this.$store.state.user.language = currLang;
    sessionStorage.setItem("language", currLang);
  },
  computed: {
    showWin10Button() {
      return `${process.env.VUE_APP_MICROSOFT_SANDBOX_ID}` !== "RETAIL";
    },

    MergingAccountHeader() {
      return this.$store.getters.translate("MergingAccountManagement");
    },
    RestartWarning() {
      return this.$store.getters.translate("MergingHomeGameRunning");
    },
    MergingHomeSwitchPsnWarning() {
      return this.$store.getters.translate("MergingHomeSwitchPsnWarning");
    },
    SteamButton() {
      return this.$store.getters.translate("MergingHomeSteamButton");
    },
    EpicButton() {
      return this.$store.getters.translate("MergingHomeEpicButton");
    },
    GoogleButton() {
      return this.$store.getters.translate("MergingHomeGoogleButton");
    },
    AppleButton() {
      return this.$store.getters.translate("MergingHomeAppleButton");
    },
    MicrosoftButton() {
      return this.$store.getters.translate("MergingHomeMicrosoftButton");
    },
    Win10Button() {
      return this.$store.getters.translate("MergingHomeWin10Button");
    },
    ItchioButton() {
      return this.$store.getters.translate("MergingHomeItchioButton");
    },
  },

  methods: {
    steamClick() {
      Object.entries(process.env).forEach(element => {
        console.log(element)
      });
      if (process.env.VUE_APP_SKIP_OAUTH === "true") {
        this.redirect("/account-management?store=steam&token=definitely_a_real_steam_token");
        return;
      }
      this.redirect(`https://steamcommunity.com/oauth/login?response_type=token&client_id=${process.env.VUE_APP_STEAM_CLIENT_ID}`);
    },
    epicClick() {
      if (process.env.VUE_APP_SKIP_OAUTH === "true") {
        this.redirect("/account-management?store=epic&token=definitely_a_real_epic_token");
        return;
      }
      this.redirect(`https://www.epicgames.com/id/authorize?client_id=${process.env.VUE_APP_EPIC_CLIENT_ID}&response_type=code&scope=basic_profile&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/epic`);
    },
    googleClick() {
      if (process.env.VUE_APP_SKIP_OAUTH === "true") {
        this.redirect("/account-management?store=google&token=definitely_a_real_google_token");
        return;
      }
      this.redirect(`https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/google&response_type=code&scope=openid profile&prompt=select_account`);
    },
    appleClick() {
      if (process.env.VUE_APP_SKIP_OAUTH === "true") {
        this.redirect("/account-management?store=google&token=definitely_a_real_google_token");
        return;
      }
      this.redirect(`https://appleid.apple.com/auth/authorize?client_id=${process.env.VUE_APP_APPLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/apple&response_type=code id_token&scope=name email&response_mode=form_post`);
    },
    microsoftClick() {
      if (process.env.VUE_APP_SKIP_OAUTH === "true") {
        this.redirect("/account-management?store=microsoft&token=definitely_a_real_microsoft_token");
        return;
      }
      document.cookie = `microsoft_sandbox=${process.env.VUE_APP_MICROSOFT_SANDBOX_ID};path=/;samesite=none;secure`;
      this.redirect(`https://login.live.com/oauth20_authorize.srf?client_id=${process.env.VUE_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/microsoft&scope=XboxLive.signin`);
    },
    win10Click() {
      if (process.env.VUE_APP_SKIP_OAUTH === "true") {
        this.redirect("/account-management?store=microsoft&token=definitely_a_real_microsoft_token");
        return;
      }
      document.cookie = `microsoft_sandbox=${process.env.VUE_APP_WIN10_SANDBOX_ID};path=/;samesite=none;secure`;
      this.redirect(`https://login.live.com/oauth20_authorize.srf?client_id=${process.env.VUE_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/microsoft&scope=XboxLive.signin`);
    },
    itchioClick() {
      if (process.env.VUE_APP_SKIP_OAUTH === "true") {
        this.redirect("/account-management?store=itchio&token=definitely_a_real_itchio_token");
        return;
      }
      this.redirect(`https://itch.io/user/oauth?client_id=${process.env.VUE_APP_ITCHIO_CLIENT_ID}&scope=profile:me&response_type=token&redirect_uri=${process.env.VUE_APP_SERVICE_URL}/itchio`);
    },

    redirect(url) {
      document.cookie = `REDIRECT=merge;max-age=600;path=/;samesite=none;secure`; // expires after 10 min
      location.href = url;
    },
  },
};
</script>

<style scoped>
#error-output {
  top: 15px;
}

.restart-warning {
  color: red;
  text-align: center;
  font-weight: bold;
  font-size: large;
  margin-bottom: 2em;
}

#submit-error-output {
  position: absolute;
  top: 75vh;
  left: 50%;
  transform: translateX(-50%);
  width: 20vw;
  text-align: center;
  color: red;
  font-size: medium;
}

/* If the screen size is 450px wide or less */
@media screen and (max-width: 450px) {
  #submit-error-output {
    top: 81vh;
    width: 90vw;
  }
}
</style>
