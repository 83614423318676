<template>
  <div>
    <div class="header-info">
      <h1>{{ Title }}</h1>
      <h2 class="text warning">
        <img class="mergeable-icon" src="../assets/MergeCross.png" />
        {{ Warning }}
      </h2>
      <p class="text friends-header">
        {{ Header }}
      </p>
      <p class="text">{{ Message }}</p>
    </div>

    <button class="submit-button" v-on:click="MergeAnyway">
      {{ ContinueButtonText }}
    </button>
    <button class="back-button" v-on:click="back">
      <img src="../assets/icon-back-arrow.png" class="back-button-icon" />
      <text class="button-text">{{ ReturnToAccountManagement }}</text>
    </button>
  </div>
</template>

<script lang="js">
export default {
  data() {
    return {
      platforms: this.$store.getters.platformData,
    };
  },
  async mounted() {
    // now that we've redirected successfully, we can delete the cookie
    document.cookie =
      "REDIRECT=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;samesite=none;secure";
  },
  computed: {
    Title() {
      return this.$store.getters.translate("MergeV2Title");
    },
    Warning() {
      return this.$store.getters.translate("MergeV2Warning");
    },
    Header() {
      return this.$store.getters.translate("MergeV2FriendsWarningHeader");
    },
    Message() {
      return this.$store.getters.translate("MergeAttentionFriendsMessage");
    },
    ContinueButtonText() {
      return this.$store.getters.translate("MergeV2LinkAnywayButton");
    },
    ReturnToAccountManagement() {
      return this.$store.getters.translate("MergeV2BackToAccountManagementButton");
    },
    PrimaryPlatforms() {
      return this.$store.state.merging.primaryKeyring?.queryResponse?.platforms;
    },
    SecondaryPlatforms() {
      return this.$store.state.merging.secondaryKeyring?.queryResponse?.platforms;
    },
  },
  methods: {
    async MergeAnyway(e) {
      e.preventDefault();

      // Since this is the last step (unlinking secondary accounts and kws permissions are checked first),
      // merge anyway can directly call merge
      const res = await this.$store.dispatch("mergeAccount", null);
      if (res?.errors) {
        this.$store.commit("saveMergingError", {
          error: this.$store.getters.translate("BackendErrorGeneric"),
          errorDetail: res.errors[0].error_text,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }
      this.$router.push({ name: "mergeSuccess" });
    },
    async back(e) {
      e.preventDefault();

      this.$router.push({ name: "previewMerge" });
    },
    ShowPrimaryPlatform(platform) {
      return this.PrimaryPlatforms?.includes(platform);
    },
    ShowSecondaryPlatform(platform) {
      return this.SecondaryPlatforms?.includes(platform);
    },
  },
};
</script>
<style scoped>
.header-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
}

.friends-header {
  color: red;
  text-align: center;
  font-weight: bold;
  font-size: large;
  max-width: 550px;
}

.mergeable-icon {
  height: 1em;
  width: 1em;
  margin-right: 5px;
}

.text {
  font-weight: bold;
  margin: 5px;
}
</style>
