const accountsServiceURL = process.env.VUE_APP_SERVICE_URL;
const backendServiceURL = process.env.VUE_APP_BACKEND_URL;
console.log(
  `Accounts URL: ${accountsServiceURL}\nBackend URL: ${backendServiceURL}`
);

async function getEosTokens(data, meta) {
  var url = `${accountsServiceURL}/eos-auth?store=${data.store}&token=${data.token}`;
  if (meta?.mergeId && meta?.type) {
    url += `&mergeId=${meta.mergeId}&type=${meta.type}`;
  }
  if (data.store == "apple") {
    url += `&name=${data.name}`;
  } else if (data.store == "microsoft") {
    url += `&userhash=${data.hash}`;
  }

  var res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    },
  });

  return res;
}

export default {
  async redeemCode({ state }, data) {
    const res = await fetch(`${backendServiceURL}/api/user/redeem-code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.user.id_token}`,
      },
      body: JSON.stringify(data),
    });

    return await res.json();
  },

  async queryPrimaryAccountBeforeMerge({ getters, commit }, authRes) {
    const url = `${backendServiceURL}/api/user/query-primary-before-merge?access_token=${authRes.token}`;
    const res = await fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${authRes.id_token}` },
    });

    let query;
    try {
      query = await res.json();
    } catch (error) {
      return {
        errors: [
          {
            title: getters.translate("MergeErrorQueryAccountHeader"),
            detail: getters.translate("BackendErrorGeneric"),
          },
        ],
      };
    }

    if (query.data) {
      commit("savePrimaryKeyringQueryResults", query.data);
    } else if (query.errors) {
      return {
        errors: [
          {
            title: getters.translate("MergeErrorQueryAccountHeader"),
            detail:
              getters.translateDetail(query.errors[0].detail) +
              ` (${query.errors[0].detail})`,
          },
        ],
      };
    }

    return query;
  },

  async querySecondaryAccountBeforeMerge({ getters, state, commit }, authRes) {
    const primaryPuid = state.merging.primaryKeyring.queryResponse.puid;
    const url = `${backendServiceURL}/api/user/query-secondary-before-merge?access_token=${authRes.token}&primary_puid=${primaryPuid}`;
    const res = await fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${authRes.id_token}` },
    });

    let query;
    try {
      query = await res.json();
    } catch (error) {
      return {
        errors: [
          {
            title: getters.translate("MergeErrorQueryAccountHeader"),
            detail: getters.translate("BackendErrorGeneric"),
          },
        ],
      };
    }

    if (query.data) {
      commit("saveSecondaryKeyringQueryResults", query.data);
    } else if (query.errors) {
      return {
        errors: [
          {
            title: getters.translate("MergeErrorQueryAccountHeader"),
            detail:
              getters.translateDetail(query.errors[0].detail) +
              ` (${query.errors[0].detail})`,
          },
        ],
      };
    }

    return query;
  },

  async requestMissingPermissions({ getters, state }, data) {
    const res = await fetch(
      `${backendServiceURL}/api/user/request-missing-permissions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.user.id_token}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (res.status >= 200 && res.status < 300) {
      return null;
    }

    let query;
    try {
      query = await res.json();
    } catch (error) {
      return {
        errors: [
          {
            title: getters.translate("MergeErrorRequestPermissionsHeader"),
            detail: getters.translate("BackendErrorGeneric"),
          },
        ],
      };
    }

    if (query.errors) {
      return {
        errors: [
          {
            title: getters.translate("MergeErrorRequestPermissionsHeader"),
            detail:
              getters.translateDetail(query.errors[0].detail) +
              ` (${query.errors[0].detail})`,
          },
        ],
      };
    }

    return query;
  },

  async twitchPin({ state }, data) {
    const url = `${accountsServiceURL}/twitch-drops-grant?pin=${data.pin}&token=${state.user.twitch_token}`;
    let res = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    if (res) {
      res = await res.json();
    }
    return res;
  },

  async linkBareAccount({ getters }, linkBody) {
    const linkRes = await fetch(`${backendServiceURL}/api/link-account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${linkBody.data.attributes.link_to_id_token}`,
      },
      body: JSON.stringify(linkBody),
    });

    try {
      var res = await linkRes.json();
    } catch (error) {
      return {
        errors: [
          {
            title: getters.translate("BackendErrorGenericHeader"),
            detail: getters.translate("BackendErrorGeneric"),
          },
        ],
      };
    }

    if (res.errors) {
      return {
        errors: [
          {
            title: getters.translate("BackendErrorGenericHeader"),
            detail:
              getters.translateDetail(res.errors[0].detail) +
              ` (${res.errors[0].detail})`,
          },
        ],
      };
    }

    return res;
  },

  async unlinkAccount({ getters }, data) {
    const url = `${accountsServiceURL}/eos-unlink?token=${data.token}`;
    const unlinkRes = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });

    if (unlinkRes.status >= 200 && unlinkRes.status < 300) {
      return unlinkRes; // success
    }

    try {
      return await unlinkRes.json();
    } catch (error) {
      return {
        errors: [
          {
            title: getters.translate("BackendErrorGenericHeader"),
            detail: getters.translate("BackendErrorGeneric"),
          },
        ],
      };
    }
  },

  async mergeAccount({ getters, state }) {
    const url = `${backendServiceURL}/api/merge-account`;
    const mergeFromPlatform = state.merging.secondaryPlatform;
    const mergeToPlatform = state.merging.primaryPlatform;
    const mergeFromIdToken = state.merging.secondaryIdToken;
    const mergeToIdToken = state.merging.primaryIdToken;
    const mergeFromPlatformToken = state.merging.secondaryPlatformToken;
    const primaryToken =
      state.merging.primaryKeyring.access_tokens[mergeToPlatform];
    const secondaryToken =
      state.merging.secondaryKeyring.access_tokens[mergeFromPlatform];
    const name = state.merging.secondaryUserName;
    const hash = state.merging.secondaryHash;
    let res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        data: {
          attributes: {
            merge_to_id_token: mergeToIdToken,
            merge_from_id_token: mergeFromIdToken,
            merge_to_access_token: primaryToken,
            merge_from_access_token: secondaryToken,
            merge_from_platform_token: mergeFromPlatformToken,
            merge_from_platform: mergeFromPlatform,
            merge_from_name: name,
            merge_from_hash: hash,
          },
        },
      }),
    });

    try {
      res = await res.json();
    } catch (error) {
      return {
        errors: [
          {
            title: getters.translate("BackendErrorGenericHeader"),
            detail: getters.translate("BackendErrorGeneric"),
          },
        ],
      };
    }

    if (res.errors) {
      return {
        errors: [
          {
            title: getters.translate("BackendErrorGenericHeader"),
            detail:
              getters.translateDetail(res.errors[0].detail) +
              ` (${res.errors[0].detail})`,
          },
        ],
      };
    }

    return res;
  },

  async eosAuth({ state, getters }, { data, meta }) {
    try {
      var authRes = await getEosTokens(data, meta);
      var res = await authRes.json();
    } catch (e) {
      return {
        errors: [
          {
            title: getters.translate("BackendErrorGenericHeader"),
            detail: getters.translate("BackendErrorGeneric"),
          },
        ],
      };
    }

    if (res.id_token && res.token) {
      sessionStorage.setItem("token", res.token);
      state.user.token = res.token;

      sessionStorage.setItem("id_token", res.id_token);
      state.user.id_token = res.id_token;

      sessionStorage.setItem("platform", data.store);
      state.user.platform = data.store;
    }

    return res;
  },
};
