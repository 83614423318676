<template>
  <div class="finish-merge">
    <h1 class="merge-header">{{ Title }}</h1>
    <div class="text">{{ Header }}</div>

    <hr class="divider" />

    <div class="platforms">
      <div>
        <p class="primary-header primary-text">{{ PrimaryAccount }}</p>
        <div class="primary-platforms">
          <p class="puid">{{ AccountId }}</p>
          <div v-for="(platform, key) in platforms" :key="key">
            <div class="account-tile" v-if="ShowPrimaryPlatform(key)">
              <img class="logo" :src="platform.logoSrc" alt="Logo" />
              <h1 class="button-text">{{ platform.name }}</h1>
            </div>
          </div>
        </div>
      </div>

      <img src="../assets/icon-plus.png" class="plus-equals-icon" />

      <div>
        <p class="primary-header">{{ AccountToLink }}</p>
        <div class="secondary-platforms">
          <p class="puid">{{ SecondaryAccountId }}</p>
          <div v-for="(platform, key) in platforms" :key="key">
            <div class="account-tile" v-if="ShowSecondaryPlatform(key)">
              <img class="logo" :src="platform.logoSrc" alt="Logo" />
              <h1 class="button-text">{{ platform.name }}</h1>
            </div>
          </div>
        </div>
      </div>

      <img src="../assets/icon-equals.png" class="plus-equals-icon" />

      <div>
        <p class="primary-header">
          <text class="primary-text">{{ PrimaryAccount }}</text>
          {{ MergeResult }}
        </p>
        <div class="resulting-platforms">
          <p class="puid">{{ AccountId }}</p>
          <div v-for="(platform, key) in platforms" :key="key">
            <div
              class="account-tile"
              v-if="ShowPrimaryPlatform(key) || ShowSecondaryPlatform(key)"
            >
              <img class="logo" :src="platform.logoSrc" alt="Logo" />
              <h1 class="button-text">{{ platform.name }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="submit-button" v-on:click="MergeAccount">
      {{ LinkButtonText }}
    </button>
    <button class="back-button" v-on:click="back">
      <img src="../assets/icon-back-arrow.png" class="back-button-icon" />
      <text class="button-text">{{ ReturnToAccountManagement }}</text>
    </button>
  </div>
</template>

<script lang="js">
export default {
  data() {
    return {
      platforms: this.$store.getters.platformData,
    };
  },
  computed: {
    showWin10Button() {
      return `${process.env.VUE_APP_MICROSOFT_SANDBOX_ID}` !== "RETAIL";
    },
    AccountId() {
      const primaryAccountId = this.$store.getters.translate("MergeV2PrimaryAccountId");
      const primaryAccountIdString = this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
      return `${primaryAccountId}: ${primaryAccountIdString ?? ""}`;
    },
    SecondaryAccountId() {
      const primaryAccountId = this.$store.getters.translate("MergeV2PrimaryAccountId");
      const primaryAccountIdString = this.$store.state.merging.secondaryKeyring?.queryResponse?.puid;
      return `${primaryAccountId}: ${primaryAccountIdString ?? ""}`;
    },
    Title() {
      return this.$store.getters.translate("MergeV2Title");
    },
    Header() {
      let header = this.$store.getters.translate("MergeV2MergeAccountsHeader");
      const platform = this.$store.getters.secondaryPlatformPretty;
      header = header.replace(/{platform}/g, function() {
        return platform;
      });
      return header;
    },
    PrimaryAccount() {
      return this.$store.getters.translate("MergingFinishPrimary");
    },
    AccountToLink() {
      return this.$store.getters.translate("MergeV2AccountToLink");
    },
    MergeResult() {
      return this.$store.getters.translate("MergeV2LinkingResult");
    },
    LinkButtonText() {
      return this.$store.getters.translate("MergingLink");
    },
    ReturnToAccountManagement() {
      return this.$store.getters.translate("MergeV2BackToAccountManagementButton");
    },
    PrimaryPlatforms() {
      return this.$store.state.merging.primaryKeyring.queryResponse?.platforms;
    },
    SecondaryPlatforms() {
      return this.$store.state.merging.secondaryKeyring.queryResponse?.platforms;
    },
    MergeButtonDisabled() {
      return !this.$store.getters.secondaryKeyringAllAuthed;
    },
  },

  methods: {
    ShowPrimaryPlatform(platform) {
      return this.PrimaryPlatforms?.includes(platform);
    },
    ShowSecondaryPlatform(platform) {
      return this.SecondaryPlatforms?.includes(platform);
    },

    back() {
      this.$router.push({ name: "accountManagement" });
    },

    async MergeAccount(e) {
      e.preventDefault();

      if (this.SecondaryPlatforms.length > 1) {
        this.$router.push({ name: "mergeAttentionUnlink" });
        return;
      }

      if (this.$store.state.merging.willLosePermissions) {
        this.$router.push({ name: "requestPermissions" });
        return;
      }

      if (this.$store.state.merging.willLoseFriends) {
        this.$router.push({ name: "mergeFriendsAttention" });
        return;
      }

      const res = await this.$store.dispatch("mergeAccount", null);
      if (res?.errors) {
        this.$store.commit("saveMergingError", {
          error: this.$store.getters.translate("BackendErrorGeneric"),
          errorDetail: res.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }
      this.$router.push({ name: "mergeSuccess" });
    },
  }
};
</script>

<style scoped>
.plus-equals-icon {
  align-self: center;
  height: 5vh;
}

hr.divider {
  margin: 3vh;
  border: 1px solid white;
  opacity: 1;
  width: 70vw;
  max-width: 1000px;
}

.merge-header {
  text-align: center;
  margin-bottom: 0.1em;
}

.platforms {
  width: 100vw;
  max-width: 1250px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
}

.primary-platforms,
.secondary-platforms,
.resulting-platforms {
  background-color: black;
  border: 4px solid #5bbc2b;
  border-radius: 10px;
  width: 300px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resulting-platforms {
  margin-bottom: 2em;
}

.secondary-platforms {
  background-color: black;
  border: 4px solid white;
}

.primary-header {
  font-family: brook, Arial, sans-serif;
  font-size: xx-large;
}

:lang(id).primary-header,
:lang(nl-NL).primary-header,
:lang(es-ES).primary-header,
:lang(es-MX).primary-header,
:lang(de-DE).primary-header {
  font-size: x-large;
}

:lang(ar).primary-header,
:lang(th).primary-header,
:lang(ja-JP).primary-header {
  font-size: large;
}

:lang(pl).primary-header {
  font-size: small;
  font-family: sans-serif;
  text-transform: uppercase;
}

:lang(tr).primary-header,
:lang(ru-RU).primary-header {
  font-size: small;
  font-family: sans-serif;
  text-transform: uppercase;
}

.primary-text {
  color: #5bbc2b;
}

#error-output {
  top: 15px;
}

td p {
  margin-bottom: 20px;
}

svg {
  margin-right: 5px;
}

#submit-error-output {
  position: absolute;
  top: 75vh;
  left: 50%;
  transform: translateX(-50%);
  width: 20vw;
  text-align: center;
  color: red;
  font-size: medium;
}

.account-warning {
  width: 25vw;
  color: red;
  font-weight: bold;
}

.puid {
  font-size: large;
  margin: 5px;
}

:lang(tr).puid,
:lang(ru-RU).puid {
  font-size: x-small;
  text-transform: uppercase;
}

/* medium screens */
@media screen and (max-width: 1000px) {
  .platforms {
    flex-direction: column;
    align-items: center;
  }
}

/* small screens */
@media screen and (max-width: 500px) {
  .plus-equals-icon {
    margin: 0.5em 30vw;
  }

  .primary-header {
    margin-bottom: 0;
  }
}

/* loc and overflow */
.finish-merge :lang(de-DE).submit-button,
.finish-merge :lang(nl-NL).submit-button {
  font-size: xx-large;
}

.finish-merge :lang(ru-RU).submit-button {
  font-size: x-large;
}
</style>
