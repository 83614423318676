<template>
  <div class="router-container">
    <div class="title">
      <img class="success-icon" src="../assets/MergeCheckmark.png" />
      <text class="success">{{ Title }}</text>
    </div>
    <div class="header-info">
      <p class="text success-header">
        {{ Header }}
      </p>
      <p class="text success-message">
        {{ Detail }}
      </p>
    </div>

    <div class="resulting-platforms">
      <div class="w-100 my-2">
        <p class="primary-account">{{ PrimaryAccount }}</p>
        <p class="account-id">{{ AccountId }}</p>
      </div>
      <div v-for="(platform, key) in platforms" :key="key">
        <div
          class="account-tile"
          v-if="ShowPrimaryPlatform(key) || ShowSecondaryPlatform(key)"
        >
          <img class="logo" :src="platform.logoSrc" alt="Logo" />
          <h1 class="button-text">{{ platform.name }}</h1>
        </div>
      </div>
    </div>
    <button class="back-button" v-on:click="BackToHome">
      <img src="../assets/icon-back-arrow.png" class="back-button-icon" />
      <text class="button-text">{{ LinkAdditionalAccounts }}</text>
    </button>
  </div>
</template>

<script lang="js">
export default {
  data() {
    return {
      platforms: this.$store.getters.platformData,
    };
  },
  async mounted() {
    // now that we've redirected successfully, we can delete the cookie
    document.cookie =
      "REDIRECT=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;samesite=none;secure";
  },
  computed: {
    AccountId() {
      const primaryAccountId = this.$store.getters.translate("MergeV2PrimaryAccountId");
      const primaryAccountIdString = this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
      return `${primaryAccountId}: ${primaryAccountIdString ?? ""}`;
    },
    Title() {
      return this.$store.getters.translate("MergeSuccess");
    },
    Header() {
      return this.$store.getters.translate("MergeV2SuccessHeader");
    },
    Detail() {
      return this.$store.getters.translate("MergeV2SuccessDetail");
    },
    PrimaryAccount() {
      return this.$store.getters.translate("MergingFinishPrimary");
    },
    LinkAdditionalAccounts() {
      return this.$store.getters.translate("MergeV2LinkAdditionalAccountsButton");
    },
    PrimaryPlatforms() {
      return this.$store.state.merging.primaryKeyring.queryResponse.platforms;
    },
    SecondaryPlatforms() {
      return this.$store.state.merging.secondaryKeyring.queryResponse.platforms;
    },
  },
  methods: {
    async BackToHome(e) {
      e.preventDefault();
      await this.$store.commit("clearMergeInfo");
      this.$router.push({ name: "home" });
    },
    ShowPrimaryPlatform(platform) {
      return this.PrimaryPlatforms.includes(platform);
    },
    ShowSecondaryPlatform(platform) {
      return this.SecondaryPlatforms.includes(platform);
    },
  },
};
</script>

<style scoped>
:lang(ru-RU).success,
:lang(tr).success {
  font-family: sans-serif;
}

.success-icon {
  height: 4em;
  width: 4em;
  margin-top: 1.2em;
}

.success-header {
  color: #0f0;
  font-weight: bold;
  margin-bottom: 0;
  font-size: x-large;
}

.success-message {
  font-size: large;
  line-height: 0.8em;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;
}

.title text {
  font-family: brook, sans-serif;
  font-size: 5em;
  margin-top: 0.25em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  font-weight: normal;
  line-height: normal;
  color: #0f0;
}

.header-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2em;
}

.primary-header {
  font-family: brook, Arial, sans-serif;
  font-size: large;
  text-align: center;
}

.primary-text {
  color: #00c96a;
}

.resulting-platforms {
  background-color: black;
  border: 5px solid #0f0;
  border-radius: 10px;
  min-height: 250px;
  min-width: 375px;
  padding: 0.5em;
  margin-bottom: 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.primary-account {
  font-size: large;
}

:lang(fr-FR).primary-account,
:lang(ko-KR).primary-account,
:lang(zh-ZW).primary-account,
:lang(zh-CN).primary-account,
:lang(zh-TW).primary-account,
:lang(th).primary-account {
  font-size: medium;
}

:lang(ar).primary-account,
:lang(ru-RU).primary-account,
:lang(ja-JP).primary-account {
  font-size: small;
}

:lang(pl).primary-account,
:lang(tr).primary-account,
:lang(ru-RU).primary-account {
  font-size: x-small;
}

.account-id {
  font-size: large;
}

:lang(fr-FR).account-id,
:lang(ko-KR).account-id,
:lang(zh-ZW).account-id,
:lang(zh-CN).account-id,
:lang(zh-TW).account-id,
:lang(th).account-id {
  font-size: medium;
}

:lang(ar).account-id,
:lang(ja-JP).account-id {
  font-size: small;
}

:lang(pl).account-id,
:lang(tr).account-id,
:lang(ru-RU).account-id {
  font-size: x-small;
}
</style>
