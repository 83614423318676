<template>
  <div class="router-container">
    <h1>{{ Title }}</h1>
    <h3 class="text warning">{{ Warning }}</h3>
    <p class="text">{{ Header }}</p>
    <p class="text warning">{{ Message }}</p>

    <div class="text warning" v-if="page.error">
      {{ MergeKWSError }}
    </div>

    <div class="kws-form">
      <form v-on:submit="submit">
        <p class="platform">{{ Platform }}</p>
        <p class="account-id">{{ AccountId }}</p>
        <input
          v-model="data.parent_email"
          type="email"
          placeholder="email@domain.com"
        />
        <button type="submit" class="submit-button">
          {{ ContinueButtonText }}
        </button>
        <button class="back-button" v-on:click="back">
          <img src="../assets/icon-back-arrow.png" class="back-button-icon" />
          <text class="button-text">{{ ReturnToAccountManagement }}</text>
        </button>
      </form>
    </div>
  </div>
</template>

<script lang="js">
const ValidEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  data() {
    return {
      page: {
        error: false,
        invalid_email: false,
      },
      data: {
        parent_email: "",
      },
    };
  },
  computed: {
    Title() {
      return this.$store.getters.translate("MergeV2Title");
    },
    Warning() {
      return this.$store.getters.translate("MergeV2Warning");
    },
    Platform() {
      return this.$store.state.user.platform;
    },
    AccountId() {
      const primaryAccountId = this.$store.getters.translate("MergeV2PrimaryAccountId");
      const primaryAccountIdString = this.$store.state.merging.primaryKeyring?.queryResponse?.puid;
      return `${primaryAccountId}: ${primaryAccountIdString ?? ""}`;
    },
    Header() {
      return this.$store.getters.translate("MergeV2KwsHeader");
    },
    Message() {
      return this.$store.getters.translate("MergeKWSMessage");
    },
    MergeKWSError() {
      return this.$store.getters.translate("MergeKWSError");
    },
    ContinueButtonText() {
      return this.$store.getters.translate("MergingContinue");
    },
    ReturnToAccountManagement() {
      return this.$store.getters.translate("MergeV2BackToAccountManagementButton");
    },
  },
  methods: {
    async back(e) {
      e.preventDefault();

      this.$router.push({ name: "previewMerge" });
    },
    async submit(e) {
      e.preventDefault();

      if (!ValidEmailRegex.test(this.data.parent_email)) {
        this.page.invalid_email = true;
        return;
      }

      this.page.invalid_email = false;

      const res = await this.$store.dispatch("requestMissingPermissions", {
        data: {
          type: "request-permissions",
          attributes: {
            parent_email: this.data.parent_email,
            user_access_token: this.$store.getters.primaryAccessToken,
          },
        },
      });

      if (res?.errors) {
        this.$store.commit("saveMergingError", {
          error: res.errors[0].title,
          errorDetail: res.errors[0].detail,
        });
        this.$router.push({ name: "mergeError" });
        return;
      }

      if (this.$store.state.merging.willLoseFriends) {
        this.$router.push({ name: "mergeFriendsAttention" });
        return;
      }
      this.$router.push({ name: "mergeSuccess" });
    },
  },
};
</script>

<style scoped>
.kws-form {
  width: 500px;
  margin: 2vh;
}

input {
  width: 500px;
  margin-bottom: 3vh;
}

.platform {
  font-family: brook, sans-serif;
  text-align: start;
  float: left;
  margin-bottom: 1%;
  font-size: 1.4em;
}

:lang(pl).platform,
:lang(tr).platform,
:lang(ru-RU).platform {
  font-family: sans-serif;
  text-transform: uppercase;
}

.puid {
  text-align: end;
  margin-bottom: 1%;
  font-size: 1.4em;
}

@media screen and (max-width: 500px) {
  .kws-form {
    width: 90vw;
  }

  input {
    width: 90vw;
  }
}
</style>
