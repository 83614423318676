import {
  PRIMARY_KEYRING_QUERY_KEY,
  SECONDARY_KEYRING_QUERY_KEY,
  PRIMARY_ACCESS_TOKEN_PREFIX,
  SECONDARY_ACCESS_TOKEN_PREFIX,
  WILL_LOSE_PERMISSIONS_KEY,
  WILL_LOSE_FRIENDS_KEY,
  mergePlatforms,
} from "./_shared";

export default {
  clearToken: (state) => {
    state.user.token = null;
    state.user.id_token = null;
    state.user.twitch_token = null;
  },
  clearMergeInfo: (state) => {
    state.merging.willLosePermissions = null;
    state.merging.willLoseFriends = null;
    state.merging.primaryKeyring.queryResponse = null;
    state.merging.primaryKeyring.access_tokens = {};
    state.merging.secondaryKeyring.queryResponse = null;
    state.merging.secondaryKeyring.access_tokens = {};
    state.merging.primaryIdToken = null;
    state.merging.primaryPlatform = null;
    state.merging.primaryPlatformToken = null;
    state.merging.primaryUserName = null;
    state.merging.primaryHash = null;
    state.merging.secondaryPlatform = null;
    state.merging.secondaryIdToken = null;
    state.merging.secondaryPlatformToken = null;
    state.merging.secondaryUserName = null;
    state.merging.secondaryHash = null;
    state.merging.errors = {};
    sessionStorage.clear();
  },
  clearMergeErrors: (state) => {
    state.merging.errors = {};
  },
  addPrimaryMergeAccessToken: (state, payload) => {
    sessionStorage.setItem(
      PRIMARY_ACCESS_TOKEN_PREFIX + payload.platform,
      payload.token
    );
    state.merging.primaryKeyring.access_tokens[payload.platform] =
      payload.token;
  },
  savePrimaryKeyringQueryResults: (state, payload) => {
    sessionStorage.setItem(PRIMARY_KEYRING_QUERY_KEY, JSON.stringify(payload));
    state.merging.primaryKeyring.queryResponse = payload;
  },

  addSecondaryMergeAccessToken: (state, payload) => {
    sessionStorage.setItem(
      SECONDARY_ACCESS_TOKEN_PREFIX + payload.platform,
      payload.token
    );
    state.merging.secondaryKeyring.access_tokens[payload.platform] =
      payload.token;
  },
  savePrimaryMergeDetails: (state, payload) => {
    sessionStorage.setItem("primary_platform", payload.platform);
    sessionStorage.setItem("primary_id_token", payload.id_token);
    sessionStorage.setItem("primary_platform_token", payload.platform_token);
    sessionStorage.setItem("secondary_user_name", payload.name);
    sessionStorage.setItem("secondary_hash", payload.hash);

    state.merging.primaryIdToken = payload.id_token;
    state.merging.primaryPlatform = payload.platform;
    state.merging.secondaryPlatformToken = payload.platform_token;
    state.merging.secondaryUserName = payload.name;
    state.merging.secondaryHash = payload.hash;
  },
  saveSecondaryMergeDetails: (state, payload) => {
    sessionStorage.setItem("secondary_platform", payload.platform);
    sessionStorage.setItem("secondary_id_token", payload.id_token);
    sessionStorage.setItem("secondary_platform_token", payload.platform_token);
    sessionStorage.setItem("secondary_user_name", payload.name);
    sessionStorage.setItem("secondary_hash", payload.hash);

    state.merging.secondaryPlatform = payload.platform;
    state.merging.secondaryIdToken = payload.id_token;
    state.merging.secondaryPlatformToken = payload.platform_token;
    state.merging.secondaryUserName = payload.name;
    state.merging.secondaryHash = payload.hash;
  },
  saveSecondaryKeyringQueryResults: (state, payload) => {
    sessionStorage.setItem(
      SECONDARY_KEYRING_QUERY_KEY,
      JSON.stringify(payload)
    );
    state.merging.secondaryKeyring.queryResponse = payload;

    state.merging.willLosePermissions = payload.will_lose_permissions;
    sessionStorage.setItem(
      WILL_LOSE_PERMISSIONS_KEY,
      payload.will_lose_permissions
    );

    state.merging.willLoseFriends = payload.will_lose_friends;
    sessionStorage.setItem(WILL_LOSE_FRIENDS_KEY, payload.will_lose_friends);
  },
  saveMergingError: (state, payload) => {
    sessionStorage.setItem("mergeError", payload.error);
    state.merging.errors.mergeError = payload.error;
    sessionStorage.setItem("mergeErrorDetail", payload.errorDetail);
    state.merging.errors.mergeErrorDetail = payload.errorDetail;
  },

  unlinkPrimaryPlatform: (state, platform) => {
    sessionStorage.removeItem(PRIMARY_ACCESS_TOKEN_PREFIX + platform);
    state.merging.primaryKeyring.access_tokens[platform] = null;

    const newPlatformList =
      state.merging.primaryKeyring.queryResponse.platforms.filter(
        (p) => p !== platform
      );
    state.merging.primaryKeyring.queryResponse.platforms = newPlatformList;
    sessionStorage.setItem(
      PRIMARY_KEYRING_QUERY_KEY,
      JSON.stringify(state.merging.primaryKeyring.queryResponse)
    );
  },
  unlinkSecondaryPlatform: (state, platform) => {
    sessionStorage.removeItem(SECONDARY_ACCESS_TOKEN_PREFIX + platform);
    state.merging.secondaryKeyring.access_tokens[platform] = null;

    const newPlatformList =
      state.merging.secondaryKeyring.queryResponse.platforms.filter(
        (p) => p !== platform
      );
    state.merging.secondaryKeyring.queryResponse.platforms = newPlatformList;
    sessionStorage.setItem(
      SECONDARY_KEYRING_QUERY_KEY,
      JSON.stringify(state.merging.secondaryKeyring.queryResponse)
    );
  },

  swapPrimaryAndSecondary: (state) => {
    const primaryTokens = state.merging.primaryKeyring.access_tokens;
    const secondaryTokens = state.merging.secondaryKeyring.access_tokens;
    const primary = state.merging.primaryKeyring;
    const secondary = state.merging.secondaryKeyring;
    state.merging.primaryKeyring = secondary;
    state.merging.secondaryKeyring = primary;

    const primaryQuery = sessionStorage.getItem(PRIMARY_KEYRING_QUERY_KEY);
    const secondaryQuery = sessionStorage.getItem(SECONDARY_KEYRING_QUERY_KEY);
    sessionStorage.setItem(PRIMARY_KEYRING_QUERY_KEY, secondaryQuery);
    sessionStorage.setItem(SECONDARY_KEYRING_QUERY_KEY, primaryQuery);

    const primaryPlatform = sessionStorage.getItem("primary_platform");
    const secondaryPlatform = sessionStorage.getItem("secondary_platform");
    state.merging.primaryPlatform = secondaryPlatform;
    state.merging.secondaryPlatform = primaryPlatform;
    sessionStorage.setItem("primary_platform", secondaryPlatform);
    sessionStorage.setItem("secondary_platform", primaryPlatform);

    const primaryIdToken = sessionStorage.getItem("primary_id_token");
    const secondaryIdToken = sessionStorage.getItem("secondary_id_token");
    state.merging.primaryIdToken = secondaryIdToken;
    state.merging.secondaryIdToken = primaryIdToken;
    sessionStorage.setItem("primary_id_token", secondaryIdToken);
    sessionStorage.setItem("secondary_id_token", primaryIdToken);

    const primaryPlatformToken = sessionStorage.getItem(
      "primary_platform_token"
    );
    const secondaryPlatformToken = sessionStorage.getItem(
      "secondary_platform_token"
    );
    state.merging.primaryPlatformToken = secondaryPlatformToken;
    state.merging.secondaryPlatformToken = primaryPlatformToken;
    sessionStorage.setItem("primary_platform_token", secondaryPlatformToken);
    sessionStorage.setItem("secondary_platform_token", primaryPlatformToken);

    const primaryUserName = sessionStorage.getItem("primary_user_name");
    const secondaryUserName = sessionStorage.getItem("secondary_user_name");
    state.merging.primaryUserName = secondaryUserName;
    state.merging.secondaryUserName = primaryUserName;
    sessionStorage.setItem("primary_user_name", secondaryUserName);
    sessionStorage.setItem("secondary_user_name", primaryUserName);

    const primaryHash = sessionStorage.getItem("primary_hash");
    const secondaryHash = sessionStorage.getItem("secondary_hash");
    state.merging.primaryHash = secondaryHash;
    state.merging.secondaryHash = primaryHash;
    sessionStorage.setItem("primary_hash", secondaryHash);
    sessionStorage.setItem("secondary_hash", primaryHash);

    for (const platform in mergePlatforms) {
      sessionStorage.removeItem(PRIMARY_ACCESS_TOKEN_PREFIX + platform);
      sessionStorage.removeItem(SECONDARY_ACCESS_TOKEN_PREFIX + platform);
    }

    for (const [platform, token] in primaryTokens) {
      if (token) {
        sessionStorage.setItem(SECONDARY_ACCESS_TOKEN_PREFIX + platform, token);
      }
    }

    for (const [platform, token] in secondaryTokens) {
      if (token) {
        sessionStorage.setItem(PRIMARY_ACCESS_TOKEN_PREFIX + platform, token);
      }
    }
  },
};
