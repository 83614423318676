<template>
  <div class="text-center mt-5">
    <div v-if="page.waiting" class="spinner-border" role="status" />

    <div v-if="page.error">
      <div>{{ page.error.detail }}</div>
      <button v-on:click="tryAgain" class="submit-button">
        {{ tryAgainButtonText }}
      </button>
    </div>
  </div>
</template>

<script lang="js">
export default {
  data() {
    return {
      page: {
        error: null,
        waiting: true,
      },
    };
  },

  computed: {
    tryAgainButtonText() {
      return this.$store.getters.translate("AuthTryAgainButtonText");
    },
  },

  methods: {
    getRedirect() {
      let redirectMode = "redeem";
      document.cookie.split(";").forEach((x) => {
        const val = x.split("=")[1];
        if (["redeem", "twitch"].includes(val)) {
          redirectMode = val;
        }
      });
      return redirectMode;
    },
    getTryAgainPath() {
      const redirectMode = this.getRedirect();
      switch (redirectMode) {
        case "redeem":
          return "redeemAuth";
        case "twitch":
          return "twitchAuth";
        default:
          return "redeemAuth";
      }
    },
    tryAgain() {
      this.$store.commit("clearToken");
      this.$router.push({ name: this.getTryAgainPath() });
    },
    async authEos() {
      const meta = {
        mergeId: this.$route.query.mergeId,
        type: "finish_auth"
      }
      const res = await this.$store.dispatch("eosAuth", {data: {
        store: this.$route.query.store,
        token: this.$route.query.token,
        name: this.$route.query.name,
        hash: this.$route.query.userhash,
      }, meta: meta});

      this.page.waiting = false;
      if (!res) {
        this.page.error = {
          detail: this.$store.getters.translate("LinkingErrorGenericError"),
        };
      } else if (res.errors) {
        this.page.error = res.errors[0];
      } else if (
        res.permission_status &&
        res.permission_status === "requested"
      ) {
        if (res.requested_text) {
          this.page.error = { detail: res.requested_text };
        } else {
          this.page.error = {
            detail: this.$store.getters.translate("MergeErrorPermissions"),
          };
        }
      } else {
        this.$router.push({ name: "redeem" });
      }
    },
    authTwitch() {
      const platform = this.$route.query.store;
      const token = this.$route.query.token;
      if (!platform || !token) {
        this.page.error = {
          detail: this.$store.getters.translate("LinkingErrorGenericError"),
        };
      } else {
        sessionStorage.setItem("platform", platform);
        this.$store.state.user.platform = platform;
        sessionStorage.setItem("twitch_token", token);
        this.$store.state.user.twitch_token = token;

        this.$router.push({ name: "pinEntry" });
      }
    },
  },

  async mounted() {
    const redirectMode = this.getRedirect();
    // now that we've redirected successfully, we can delete the cookie
    document.cookie =
      "REDIRECT=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;samesite=none;secure";

    if (redirectMode === "twitch") {
      this.authTwitch();
    } else {
      this.authEos();
    }
  },
};
</script>

<style scoped>
#error-output {
  top: 15px;
}

#submit-error-output {
  position: absolute;
  top: 75vh;
  width: 20vw;
  text-align: center;
  color: red;
  font-size: medium;
}
</style>
